import './Navbar.css';
import React from "react";
import {NavLink} from 'react-router-dom';
import { useState } from 'react';

const Navbar = () => {
   const [isOpen, setOpen] = useState(false);

   const closeNav = () => setOpen(false);
   
   return(
      <nav className = "navbar isPrimary"
       role = "navigation"
       aria-label = "main navigation"
      >
         <div className = "container">
            <div className = "navbar-brand">
               <button className ={`navbar-burger burger ${isOpen && 'is-active'}`}
               aria-label = "menu"
               aria-expanded = "false"
               onClick = {() => setOpen(!isOpen)}
               >
                  <span aria-hidden = "true"></span>
                  <span aria-hidden = "true"></span>
                  <span aria-hidden = "true"></span>
               </button>
            </div>
            <div className={`navbar-menu ${isOpen && 'is-active'}`}>
               <div className = "navbar-start">
               <NavLink className="navbar-items" activeClassName= "is-active" to = "/" exact onClick = {closeNav}> 
                  Home
               </NavLink>
               <NavLink className="navbar-items" activeClassName= "is-active" to = "/About" exact onClick = {closeNav}> 
                  About
               </NavLink>
               <NavLink className="navbar-items" activeClassName= "is-active" to = "/projects" exact onClick = {closeNav}> 
                  Projects & Experience
               </NavLink>
               <NavLink className="navbar-items" activeClassName= "is-active" to = "/contact" exact onClick = {closeNav}> 
                  Contact
               </NavLink>
               </div>
               <div className = "versionTag">
                  <p>V. 2.2.7</p>
               </div>
         </div>   
      </div>
      </nav>
    );
}
export default Navbar;
import { Analytics } from "@vercel/analytics/react";
import './App.css';
import Navbar from './Navbar.js';
import React from "react";
import Home from './Home.js';
import Skills from './skills.js';
import Projects from './projects.js';
import Contact from './contact.js';
import About from './About.js';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SpeedInsights } from "@vercel/speed-insights/react";

function App() {
  return (
    <div className="App">
      <link href='https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css' rel='stylesheet'></link>
      <script src="https://unpkg.com/typed.js@2.1.0/dist/typed.umd.js"></script>
      <BrowserRouter>
        <Navbar />
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/About" element={<About />} />
            <Route path="/Skills" element={<Skills />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
            <Analytics /> 
            <SpeedInsights/>
      </BrowserRouter>
    </div>
  );
}

export default App;

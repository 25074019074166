import React from "react";
import './contact.css';

function Contact() {
    return (
        <div className="contact">
            <header>
            <link href='https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css' rel='stylesheet'></link>
            </header>
            <body>
            <div id = "contact" className="contact-head">
                <h3>Contact<span> Me</span></h3>
            </div>
            <div className="contact-gmail">
                <h4><i className='bx bxl-gmail'></i> :cmsalinas2000@gmail.com</h4>
            </div>
            <div className="contact-linkedIn">
                <h4><i className='bx bxl-linkedin-square'></i><a href='https://www.linkedin.com/in/christian--salinas/' target="_blank" rel="noopener noreferrer">My LinkedIn</a></h4>
            </div>
        </body>
      </div>
    );
}
export default Contact;
import React from 'react';
import './skills.css';

function skills() {
return(
    <div className = "skills">
        <header>
            <link href='https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css' rel='stylesheet'></link>
        </header>
        <body>
            <div id = "skills" className="SkillsHead">
                <h3>My<span> Skills</span></h3>
            </div>
            <div className = "skill-list">
                <div className="skill-box-one">
                <h2>Technical Skills</h2>
                    <i class='bx bxl-python bx-tada bx-rotate-90'></i>
                    <i class='bx bxl-javascript bx-tada bx-rotate-90' ></i>
                    <i class= "bx bxl-typescript bx-tada bx-rotate-90"></i>
                    <i class='bx bxl-html5 bx-tada bx-rotate-90' ></i>
                    <i class='bx bxs-file-css bx-tada bx-rotate-90' ></i>
                    <i class='bx bxl-java bx-tada bx-rotate-90' ></i>
                    <i class='bx bxl-react bx-tada bx-rotate-90' ></i>
                </div>
                <div className="skill-box-two">
                <h2>Tools Known</h2>
                    <i class='bx bxl-git bx-tada' ></i>
                    <i class='bx bxl-unity bx-tada' ></i>
                    <i class='bx bxl-github bx-tada' ></i>
                    <i class='bx bxl-visual-studio bx-tada' ></i>
                    <i class='bx bxl-gitlab bx-tada'></i>
                    <i class='bx bxl-figma bx-tada'></i>
                </div>
            </div>
        </body>
    </div>
);
}
export default skills;
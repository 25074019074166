import React from 'react';
import './Home.css';
import { ReactTyped } from "react-typed";

const Home = () => {
 
    return (
    <div className='home'>
     <div className = "Home-Content">
        <h1> <ReactTyped 
        strings = {[
            'Hello, I am Christian Salinas',
            'I am a Software Engineer',
            'I am a Backend Developer',
            'I am a Student at UTSA',
        ]}
        typeSpeed = {40}
        backSpeed = {70}
        loop
        />
        </h1>
      </div>
        <div className="home-sci">
                <a href="https://github.com/ChristianSalinas722" target="_blank" rel="noopener noreferrer"><i className='bx bxl-github'></i></a>
                <a href="https://www.linkedin.com/in/christian--salinas/" target="_blank" rel="noopener noreferrer"><i className='bx bxl-linkedin-square' ></i></a>
        </div>
    </div>
    );
}
export default Home;
import { Analytics } from "@vercel/analytics/react";
import './About.css';
import './skills.css';
function About() {
    return (
    <div className="about">
        <header>
            <link href='https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css' rel='stylesheet'></link>
        </header>
        <div className="about-info">
            <div id = "about" className="AboutHead">
                <h3>About<span> ME</span></h3>
                <p>Hi there, I'm Christian Salinas, I'm a Computer Science student at The University of Texas at San Antonio(UTSA). 
                Throughout my journey, I've contributed to projects like Video Games and a Motion Capture software for physical therapy research. 
                I'm passionate about pushing the boundaries of technology, and I am excited to connect with like-minded individuals for creative collaborations. 
                Let's dive into the world of possibilities together, and I'm eager to create something amazing! 👋🚀
                </p>  
            </div>
            <div className="about-img">
                <img src="images/9664ba6e9e429bd48ba3c1b349920767-sticker.png" alt="about-pic" />
                <div className="about-btn">
                    <a href="https://drive.google.com/file/d/1ITc9mzFuYvEz0Nj-rr4AY6IbEv26vBKE/view?usp=sharing" target="_blank" rel="noopener noreferrer">Download Resume</a>
                </div>
            </div>
        </div>
        <div className = "about-school">
            <div id = "school" className="SchoolHead">
                <h3>Education</h3>
            </div>
            <div className = "school-info">
                <div className = "school-img">
                        <img src = "images/aerial780.jpg" alt = "UTSA" />
                </div>
                <p>I'm a Computer Science major at the University of Texas at San Antonio, set to graduate in December 2024.
                    Through active involvement in organizations like ACM and SHPE, 
                    I've honed my networking skills, connecting with peers and industry professionals. 
                    Additionally, I've taken on leadership roles within these organizations to further develop my personal skills.
                </p>
            </div>
        </div>
        <div className = "skills">
            <div id = "skills" className="SkillsHead">
                <h3>My<span> Skills</span></h3>
            </div>
            <div className = "skill-list">
                <div className="skill-box-one">
                <h2>Technical Skills</h2>
                <h3>Python, JavaScript, TypeScript, HTML, CSS, Java, React</h3>
                    <i class='bx bxl-python bx-tada bx-rotate-90'></i>
                    <i class='bx bxl-javascript bx-tada bx-rotate-90' ></i>
                    <i class= "bx bxl-typescript bx-tada bx-rotate-90"></i>
                    <i class='bx bxl-html5 bx-tada bx-rotate-90' ></i>
                    <i class='bx bxs-file-css bx-tada bx-rotate-90' ></i>
                    <i class='bx bxl-java bx-tada bx-rotate-90' ></i>
                    <i class='bx bxl-react bx-tada bx-rotate-90' ></i>
                </div>
                <div className="skill-box-two">
                <h2>Tools Known</h2>
                <h3>Git, GitLab, GitHub, Unity, Visual Studio, Figma</h3>
                    <i class='bx bxl-git bx-tada' ></i>
                    <i class='bx bxl-gitlab bx-tada'></i>
                    <i class='bx bxl-github bx-tada' ></i>
                    <i class='bx bxl-unity bx-tada' ></i>
                    <i class='bx bxl-visual-studio bx-tada' ></i>
                    <i class='bx bxl-figma bx-tada'></i>
                </div>
            </div>
        </div>
        <Analytics /> 
    </div>
    );
}
export default About;